@import url(https://fonts.googleapis.com/css2?family=Open+Sans);
@import url(https://fonts.googleapis.com/css2?family=Shippori+Mincho);
@import url(https://fonts.googleapis.com/css2?family=Cormorant);
#modal {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
  position: absolute;
  background-color: white;
  border-radius: 1.5rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  /* min-width: 25rem; */
}



#password-error {
  font-size: 10.5px;
}

.session-modal {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border: 2px solid black;
  border-radius: 1.5rem;
  grid-gap: 1rem;
  gap: 1rem;
}

.session-heading {
  font-size: 18px;
  color: #222222;
  margin: 0;
}

.session-form {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.session-section {
  display: flex;
  flex-direction: column;
  grid-gap: .15rem;
  gap: .15rem;
}

.session-field {
  color: #222222;
  font-size: 12px;
  font-weight: 600;
}

.session-error {
  font-size: 12px;
  font-weight: 600;
  color: red;
}

.session-input {
  border: none;
  height: 2.5rem;
  border-radius: .35rem;
  border: 2px solid #e9e9e9;
  min-width: 20rem;
}

.session-input:focus {
  outline: none;
  border: 2px solid #DEDEDE;
}

.session-submit {
  border: none;
  background-color: #222222;
  color: white;
  font-weight: 600;
  padding: 1rem;
  border-radius: 2.5rem;
}

.session-submit:hover {
  cursor: pointer;
  background-color: #3E3E3E;
  box-shadow: 0 0 8px 1px #919191;
}

.submit-disabled {
  border: none;
  background-color: #919191;
  color: white;
  font-weight: 600;
  padding: 1rem;
  border-radius: 2.5rem;
}

.session-heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.register {
  cursor: pointer;
  background: none;
  font-weight: 600;
  color: #222222;
  border: 2px solid #222222;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: .5rem .75rem;
  border-radius: 1.5rem;
}

.register:hover {
  background-color: #e9e9e9;
  box-shadow: 0 0 8px 1px #e9e9e9;
}

.register:active {
  background-color: #DEDEDE;
}

#unauthenticated-dropdown {
  display: flex;
  flex-direction: column;
  margin: .5rem;
  grid-gap: .5rem;
  gap: .5rem;
}

#profile-section {
  display: flex;
  align-items: center;
}

#shop-manager-link {
  font-size: 1.25rem;
  color: #919191;
  padding: .5rem 1rem;
  border-radius: 1.25rem;
}

#shop-manager-link:hover {
  background-color: #e9e9e9;
}

#cart-link {
  font-size: 1.25rem;
  color: #919191;
  padding: .5rem 1rem;
  border-radius: 1.25rem;
}

#cart-link:hover {
  background-color: #e9e9e9;
}

.profile-dropdown {
  position: absolute;
  z-index: 1;
  right: 1rem;
  top: 3.75rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid #DEDEDE;
  border-radius: .75rem;
}

#profile-button {
  border: none;
  background: none;
  font-size: 1.5rem;
  color: #919191;
  padding: .5rem 1rem;
  border-radius: 1.25rem;
  margin-right: 1rem;
}

#profile-button:hover {
  cursor: pointer;
  background-color: #e9e9e9;
}

.hidden {
  display: none;
}

.drop-down-text {
  margin: .1rem 0;
  font-size: 14px;
  font-weight: 500;
  color: #222222;
  padding: .5rem .75rem 0;
}

#drop-down-username {
  margin: .1rem 0;
  font-size: 14px;
  font-weight: 600;
  color: #222222;
}

.drop-down-link {
  text-decoration: none;
}

#navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

#home {
  text-decoration: none;
  font-family: 'Shippori Mincho';
  font-weight: bolder;
  font-size: 36px;
  letter-spacing: 1px;
  color: #F1641E;
}

#sign-out-link {
  text-decoration: none;
}

#log-out-section {
  padding: .5rem .75rem;
  border-radius: 0 0 .75rem .75rem;
}

#log-out {
  border: none;
  background: none;
  padding: 0;
  color: #222222;
}

#log-out:hover {
  cursor: pointer;
}

#log-out-section:hover {
  background-color: #e9e9e9;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #919191;
  color: white;
  text-align: center;
  padding: .5rem;
  border-radius: 6px;
  font-size: 14px;
  position: absolute;
  z-index: 1;
  right: 1.25rem;
  top: 4.25rem;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

#personal-links-container {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.personal-link-wrap {
  all: unset;
}

#personal-links-name {
  font-weight: bold;
  color: #919191;
}

.personal-link {
  cursor: pointer;
  color: #919191;
}

.personal-link:hover {
  color: #CBCBCB;
}

.img_url {
  margin: 0;
  font-size: 11px;
  font-weight: bold;
}

.form-page {
  margin: 0 3rem;
}

.form-heading {
  font-family: 'Shippori Mincho';
  font-weight: 500;
  color: #222222;
}

.form-body {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  min-width: 25rem;
  max-width: 70rem;
  /* border: 1px solid red; */
}

.form-section {
  width: 100%;
  display: flex;
  align-items: center;
}

.form-label {
  font-size: 14px;
  font-weight: 600;
  color: #222222;
  width: 30%;
  min-width: 13.5rem;
  max-width: 20rem;
}

.error-text {
  color: red;
  font-size: 14px;
  font-weight: 600;
  width: 30%;
  min-width: 13.5rem;
  max-width: 20rem;
}

.form-input {
  width: 70%;
  min-width: 20rem;
  max-width: 50rem;
  border: none;
  height: 2.5rem;
  box-sizing: border-box;
  color: #919191;
  font-family: 'Open Sans';
  border: 2px solid #e9e9e9;
  border-radius: .35rem;
  padding: .5rem;
}

#form-description {
  display: flex;
  align-items: flex-start;
}

#description-input {
  min-height: 5rem;
  padding: .5rem;
}

.form-submit-section {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.create-shop-breadcrumb {
  font-size: 18px;
  font-weight: 500;
  color: #222222;
  border: 1px solid #222222;
  padding: .5rem 1rem;
  border-radius: 1.25rem;
  cursor: pointer;
}

.create-shop-breadcrumb:hover {
  background-color: #e9e9e9;
  box-shadow: 0 0 8px 1px #DEDEDE;
}

.create-shop-breadcrumb:active {
  background-color: #DEDEDE;
}

.create-shop-submit {
  font-size: 18px;
  font-weight: 500;
  color: white;
  background-color: #F1641E;
  border: 1px solid #DEDEDE;
  padding: .5rem 1rem;
  border-radius: 1.25rem;
  cursor: pointer;
}

.create-shop-submit:hover {
  background-color: #D95C1F;
  box-shadow: 0 0 8px 1px #DEDEDE;
}

.create-shop-submit:active {
  background-color: #C6541A;
}

.img-input {
  box-sizing: border-box;
  color: #919191;
  font-family: 'Open Sans';
  cursor: pointer;
}

.form-breadcrumbs {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

#update-submit-section {
  margin-top: 1rem;
  max-width: 70rem;
}

.modal-body-1 {
  padding: 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.modal-heading-1 {
  margin: 0 0 1rem 0;
  padding: 0;
  font-family: 'Shippori Mincho';
  font-size: 28px;
}

.modal-buttons-1 {
  display: flex;
  justify-content: space-between;
}

#shops-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 0 3rem 0 3rem;
}

#shops-list-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-family: 'Shippori Mincho';
}

#shops-list {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  justify-content: flex-start;
  /* margin: 0 3rem; */
}

.shop-link {
  text-decoration: none;
  border-radius: .5rem;
}

/* .shop-link:hover {
  box-shadow: 0 0 8px 1px #DEDEDE;
} */

.shop-img {
  width: 18rem;
  height: 15rem;
  object-fit: cover;
  border-radius: .5rem .5rem 0 0;
}

.shop-link-title {
  margin: .5rem;
  width: 18rem;
}

#shop-details-page {
  display: flex;
}

#shop-details-container {
  width: 100%;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  grid-gap: .75rem;
  gap: .75rem;
  margin: 0 3rem;
}

#shop-details-banner {
  width: 100%;
  height: 5rem;
  object-fit: cover;
}

#shop-details-title {
  margin: .75rem 0;
  padding: 0;
  font-family: 'Shippori Mincho';
  font-weight: 500;
  overflow-wrap: anywhere;
  min-width: 35rem;
  width: 100%;
  max-width: 1500px;
}

.shop-details-text {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

#shop-details-desc {
  width: 50%;
}

#shop-products-container {
  display: flex;
  justify-content: flex-start;
}

#shop-products {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
}

.shop-product-link {
  text-decoration: none;
  color: #222222;
  font-weight: 500;
  border-radius: .75rem;
}

.shop-product-link:hover {
  box-shadow: 0 0 8px 1px #919191;
}

.shop-product-img {
  width: 18rem;
  height: 15rem;
  object-fit: cover;
  border-radius: .75rem .75rem 0 0;
}

.shop-product-info {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0 .5rem;
}

.product-link-text {
  margin: 0 0 .25rem 0;
  padding: 0;
}

#shop-details-update {
  font-size: 18px;
  font-weight: 500;
  color: #222222;
  border: 1px solid #222222;
  padding: .5rem 1rem;
  border-radius: 1.25rem;
  cursor: pointer;
}

#shop-details-update:hover {
  background-color: #e9e9e9;
  box-shadow: 0 0 8px 1px #e9e9e9;
}

#shop-details-update:active {
  background-color: #DEDEDE;
}

#products-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 0 3rem 0 3rem;
}

#products-list-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

#products-list {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  justify-content: flex-start;
  margin: 0 3rem;
}

.product-link-wrapper {
  border-radius: .5rem;
}

.product-link-wrapper:hover {
  box-shadow: 0 0 8px 1px #DEDEDE;
}

.product-link {
  text-decoration: none;
}


.product-img {
  width: 18rem;
  height: 15rem;
  object-fit: cover;
  /* border-radius: .2rem .2rem 0 0; */
}

.product-link-info {
  display: flex;
  justify-content: space-between;
  padding-left: .5rem;
  padding-right: .5rem;
}

.product-link-title {
  font-weight: bold;
}

#product-details-body {
  width: 100%;
  display: flex;
  justify-content: center;
}

#product-details-page {
  max-width: 1500px;
  width: 100%;
  margin: 0;
  margin-top: 2rem;
  padding: 0;
  display: flex;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
}

.product-details-images {
  width: 40%;
  display: flex;
  flex-direction: column;
}

#product-details-image {
  max-width: 750px;
  max-height: 25rem;
  object-fit: cover;
  border-radius: .5rem;
}

.product-details-information {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 30%;
  grid-gap: 1rem;
  gap: 1rem;
}

#product-details-price {
  margin: 0;
  padding: 0;
  color: #222222;
}

.product-details-heading {
  margin: 0;
  padding: 0;
  color: #222222;
  font-size: 18px;
  overflow-wrap: break-word;
}

.product-details-desc {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  color: #919191;
  letter-spacing: .3px;
}

#product-details-shop {
  margin: 0;
  padding: 0;
}

#product-details-update {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  color: #222222;
  border: 1px solid #222222;
  padding: .5rem 0;
  border-radius: 1.25rem;
  cursor: pointer;
}

#product-details-update:hover {
  background-color: #e9e9e9;
  box-shadow: 0 0 8px 1px #e9e9e9;
}

#product-details-update:active {
  background-color: #DEDEDE;
}

#add-to-cart {
  cursor: pointer;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  color: white;
  background-color: #F1641E;
  border: 1px solid #919191;
  padding: .5rem 0;
  border-radius: 1.25rem;
}

#add-to-cart:hover {
  background-color: #C25017;
}

#add-to-cart:active {
  background-color: #913C11;
}

#product-shop {
  text-decoration: none;
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

#product-shop-icon {
  height: 4rem;
  width: 4rem;
  object-fit: cover;
  border-radius: 2rem;
}

#product-shop-title {
  color: #222222;
}

#product-small-images {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: .5rem 0;
  max-width: 750px;
  grid-gap: 1%;
  gap: 1%;
}

.product-small-image {
  width: 19%;
  height: 4rem;
  object-fit: cover;
  border-radius: .25rem;
  cursor: pointer;
}

.small-image-selected {
  width: 19%;
  height: 4rem;
  object-fit: cover;
  border-radius: .25rem;
  cursor: pointer;
  border: 2px solid #f3c5ae;
}

.product-small-image:hover {
  border: 2px solid #F1641E;
}

#qty-add-inputs {
  display: flex;
  flex-direction: column;
  grid-gap: .5rem;
  gap: .5rem;
}

.add-cart-success {
  margin: 0;
  padding: 0;
  margin-left: .5rem;
  color: #F1641E;
  font-size: 10px;
  font-weight: 600;
}

.img_url {
  margin: 0;
  font-size: 11px;
  font-weight: bold;
}

#price-label {
  display: flex;
  justify-content: space-between;
  min-width: 13.25rem;
  max-width: 19.75rem;
  padding-right: .25rem;
}

#inventory-input {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.dollar-sign {
  color: #919191;
}

.manager-content {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
}

.manager-create {
  width: 18rem;
  font-family: 'Shippori Mincho';
  font-size: 18px;
  font-weight: 500;
  color: white;
  background-color: #F1641E;
  border: 1px solid #919191;
  padding: .25rem 0 .5rem 0;
  border-radius: 1.25rem;
  cursor: pointer;
}

.manager-create:hover {
  background-color: #D95C1F;
  box-shadow: 0 0 8px 1px #919191;
}

.manager-create:active {
  background-color: #C6541A;
}

#shops-list {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  justify-content: flex-start;
}

.shop-list-item {
  border-radius: .5rem;
}

.shop-list-item:hover {
  box-shadow: 0 0 8px 1px #919191;
}

.shop-img {
  width: 20rem;
  height: 15rem;
  object-fit: cover;
  border-radius: .5rem .5rem 0 0;
}

.shops-list-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: .5rem;
  gap: .5rem;
  padding: 0 .5rem .5rem .5rem;
}

.shops-list-text {
  text-decoration: none;
  color: #222222;
}

.shops-list-title {
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  padding: 0;
}

.shops-list-category {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.shops-list-update {
  text-decoration: none;
  color: #919191;
  padding: .5rem;
  border-radius: 2rem;
}

.shops-list-update:hover {
  background-color: #e9e9e9;
}

.manager-body {
  display: flex;
  justify-content: center;
}

.manager-page {
  margin: 0 3rem;
  width: 100%;
  max-width: 1500px;
}

.manager-page-heading {
  font-family: 'Shippori Mincho';
  font-weight: 500;
  color: #222222;
}

.manager-headings-container {
  display: flex;
  align-items: center;
}

.manager-heading {
  display: flex;
  align-items: center;
  font-family: 'Shippori Mincho';
  font-weight: 500;
  padding: .25rem .75rem .5rem .75rem;
  color: #222222;
}

.manager-toggle {
  display: flex;
  align-items: center;
  font-family: 'Shippori Mincho';
  font-weight: 500;
  color: #F1641E;
  padding: .25rem .75rem .5rem .75rem;
  border-radius: 1.25rem;
}

.manager-toggle:hover {
  cursor: pointer;
  background-color: #e9e9e9;
}

.manager-container {
  display: flex;
  flex-direction: column;
}

#landing-products-page {
  margin: 0 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

#landing-products-container {
  display: flex;
  max-width: 1500px;
  grid-gap: .5rem;
  gap: .5rem;
  margin: 2rem 0 1rem;
}

#large-link {
  width: 40%;
  position: relative;
}

#large-price {
  bottom: 7%;
}

#large-landing-product {
  width: 100%;
}

#large-product-img {
  width: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
  border-radius: .75rem;
}

#large-product-img:hover {
  box-shadow: 0 0 8px 1px #919191;
}


#small-landing-products {
  width: 60%;
  display: flex;
  flex-direction: column;
  grid-gap: .25rem;
  gap: .25rem;
}

.landing-link {
  width: 33%;
  margin: 0;
  padding: 0;
}

.small-product-img {
  width: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
  border-radius: .75rem;
}

.small-product-img:hover {
  box-shadow: 0 0 8px 1px #919191;
}

.small-row {
  margin: 0;
  padding: 0;
  display: flex;
  grid-gap: .5rem;
  gap: .5rem;
}

#landing-carousel-section {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1500px;
  margin-top: 3rem;
}

.landing-carousel-img {
  height: 15rem;
  object-fit: cover;
  border-radius: .75rem;
}

.thumb {
  height: 3.5rem;
}

.control-arrow {
  width: 15%;
  border-radius: .75rem;
}

.carousel-status {
  visibility: hidden;
}

.thumbs-wrapper {
  visibility: hidden;
}

.landing-link {
  position: relative;
}

.small-price {
  position: absolute;
  margin: 0;
  padding: 0;
  bottom: 10%;
  right: 5%;
  color: #919191;
  background-color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: .1rem .5rem;
  font-size: 12px;
  font-weight: bolder;
  border-radius: .6rem;
}

.shop-carousel-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-products-link {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Shippori Mincho';
  color: white;
  background-color: #F1641E;
  border: 1px solid #919191;
  padding: .5rem 1rem;
  border-radius: 1.25rem;
}

.landing-products-link:hover {
  background-color: #D95C1F;
  box-shadow: 0 0 8px 1px #DEDEDE;
}

.landing-products-link:active {
  background-color: #C6541A;
}

#product-link {
  max-width: 1500px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.shop-carousel-info {
  display: flex;
  /* justify-content: center; */
  text-decoration: none;
  grid-gap: .75rem;
  gap: .75rem;
  position: absolute;
  /* top: 35%; */
  /* left: 40%; */
  background-color: white;
  padding: 1rem;
  border-radius: 3rem
}

.shop-carousel-info:hover {
  box-shadow: 0 0 8px 1px #919191;
}


.landing-shop-title {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: 'Shippori Mincho';
  color: #919191;
}

/* #landing-products-page {
  margin: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

#landing-products-container {
  display: flex;
  gap: .75rem;
  width: 100%;
  max-width: 1500px;
}

#large-link {
  width: 40%;
}

#large-landing-product {}

#large-product-img {
  aspect-ratio: 4/3;
  width: 100%;
  object-fit: cover;
  border-radius: .75rem;
}

#large-price {
  position: relative;
  bottom: 14%;
  left: 81%;
  background-color: white;
  width: fit-content;
  padding: .1rem .25rem;
  font-size: 12px;
  font-weight: 600;
  border-radius: .5rem;
}

#small-landing-products {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
}

.small-row {
  display: flex;
  gap: .75rem;
}

.small-product-img {
  width: 100%;
  height: 50%;
  object-fit: cover;
  border-radius: .75rem;
}

.small-price {
  position: relative;
  bottom: 35%;
  left: 60%;
  background-color: white;
  width: fit-content;
  padding: .1rem .25rem;
  font-size: 12px;
  font-weight: 600;
  border-radius: .5rem;
}

.landing-link {
  border-radius: .75rem;
  width: 30%;
  text-decoration: none;
}

.landing-link:hover {
  box-shadow: 0 0 8px 1px #919191;
}

.landing-products-link {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Shippori Mincho';
  color: white;
  background-color: #F1641E;
  border: 1px solid #919191;
  padding: .5rem 1rem;
  border-radius: 1.25rem;
  margin-right: 5%;
}

.landing-products-link:hover {
  background-color: #D95C1F;
  box-shadow: 0 0 8px 1px #DEDEDE;
}

.landing-products-link:active {
  background-color: #C6541A;
}

#landing-links {
  max-width: 1500px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

#landing-shops-link {
  color: #222222;
  background-color: #e9e9e9;
}

#landing-shops-link:hover {
  background-color: #DEDEDE;
  box-shadow: 0 0 8px 1px #DEDEDE;
}

#landing-shops-link:active {
  background-color: #d2d2d2;
} */

#products-list {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.product-link {
  all: unset;
  cursor: pointer;
}

.product-img {
  width: 18rem;
  height: 15rem;
  object-fit: cover;
  border-radius: .5rem .5rem 0 0;
}

.products-list-delete {
  border: none;
  text-decoration: none;
  background: none;
  font-family: 'Open Sans';
  font-size: 18px;
  border-radius: 2rem;
  padding: .5rem;
  color: #919191;
}

.products-list-delete:hover {
  cursor: pointer;
  background-color: #e9e9e9;
}

.product-link-details {
  display: flex;
  width: 18rem;
}

#manager-page {
  margin: 0 3rem;
}

#manager-page-heading {
  font-family: 'Shippori Mincho';
  font-weight: 500;
  color: #222222;
}

.manager-headings-container {
  display: flex;
  align-items: center;
}

.manager-heading {
  display: flex;
  align-items: center;
  font-family: 'Shippori Mincho';
  font-weight: 500;
  padding: .25rem .75rem .5rem .75rem;
  color: #222222;
}

.manager-toggle {
  display: flex;
  align-items: center;
  font-family: 'Shippori Mincho';
  font-weight: 500;
  color: #F1641E;
  padding: .25rem .75rem .5rem .75rem;
  border-radius: 1.25rem;
}

.manager-toggle:hover {
  cursor: pointer;
  background-color: #e9e9e9;
}

.manager-container {
  display: flex;
  flex-direction: column;
}

.cart-item-container {
  display: flex;
  flex-direction: column;
  grid-gap: .75rem;
  gap: .75rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.cart-item-wrapper {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.cart-item {
  all: unset;
  display: flex;
  grid-gap: .5rem;
  gap: .5rem;
  border-radius: .5rem;
  height: 9rem;
  box-sizing: content-box;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.cart-item:hover {
  box-shadow: 0 0 8px 1px #e9e9e9;
}

.cart-thumbnail {
  object-fit: cover;
  width: 12rem;
  height: 9rem;
  border-radius: .5rem 0 0 .5rem;
}

.cart-item-details {
  width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.cart-item-details-price {
  margin: 0;
  padding: 0;
  color: #222222;
  font-size: 14px;
}

.cart-item-headings {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
  grid-gap: .5rem;
  gap: .5rem;
}

.cart-item-details-heading {
  margin: 0;
  padding: 0;
  color: #222222;
  font-size: 18px;
}

.cart-item-store {
  margin: 0;
  padding: 0;
  color: #E9E9E9;
  font-size: 14px;
}

.cart-item-store:hover {
  text-decoration: underline;
  color: #F1641E;
}

.cart-item-store:active {
  text-decoration: underline;
  color: #C25017;
}

.cart-item-details-category {
  margin: 0;
  padding: 0;
  color: #919191;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  flex-wrap: wrap;
}

.cart-item-details-desc {
  margin: 0;
  padding: 0;
  color: #919191;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
}

.cart-product-link {
  all: unset;
  cursor: pointer;
}

.cart-qty-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: .5rem .75rem .75rem 0;
}

.cart-qty-price {
  width: 8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-qty {
  display: flex;
  align-items: center;
  grid-gap: .25rem;
  gap: .25rem;
}

.cart-qty-label {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  color: #919191;
}

.cart-qty-input {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  color: #919191;
  width: 3rem;
  padding: 2px;
  border: 2px solid #E9E9E9;
  border-radius: .3rem;
}

.cart-qty-input:focus {
  outline: none;
}

.cart-error {
  color: #F1641E;
  font-size: 10px;
  font-weight: 600;
  width: 8rem;
}

#sub-cart-items {
  background-color: #919191;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40.25rem;
  height: 3rem;
  border-radius: .5rem;
  padding: 0 .5rem 0 1rem;
}

#subtotal-text {
  color: white;
  letter-spacing: .5px;
}

.clear-cart-modal {
  all: unset;
  background-color: none;
}

#clear-cart {
  cursor: pointer;
  color: white;
  width: 1rem;
  height: 1rem;
  padding: .5rem;
  border-radius: 100%;
}

#clear-cart:hover {
  background-color: #747474;
}

#clear-cart:active {
  background-color: #575757;
}

.placeholder-text {
  font-family: 'Shippori Mincho';
  font-weight: 500;
  color: #919191;
}

#clear-cart-item {
  cursor: pointer;
  color: #E9E9E9;
  text-align: right;
}

#clear-cart-item:hover {
  color: #BABABA;
}

#clear-cart-item:active {
  color: #8B8B8B;
}

.clear-qty-section {
  display: flex;
  flex-direction: column;
}

/* TODO Add site wide styles */

html,
body {
  margin: 0;
  font-family: 'Open Sans';
}

