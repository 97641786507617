.img_url {
  margin: 0;
  font-size: 11px;
  font-weight: bold;
}

.form-page {
  margin: 0 3rem;
}

.form-heading {
  font-family: 'Shippori Mincho';
  font-weight: 500;
  color: #222222;
}

.form-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 25rem;
  max-width: 70rem;
  /* border: 1px solid red; */
}

.form-section {
  width: 100%;
  display: flex;
  align-items: center;
}

.form-label {
  font-size: 14px;
  font-weight: 600;
  color: #222222;
  width: 30%;
  min-width: 13.5rem;
  max-width: 20rem;
}

.error-text {
  color: red;
  font-size: 14px;
  font-weight: 600;
  width: 30%;
  min-width: 13.5rem;
  max-width: 20rem;
}

.form-input {
  width: 70%;
  min-width: 20rem;
  max-width: 50rem;
  border: none;
  height: 2.5rem;
  box-sizing: border-box;
  color: #919191;
  font-family: 'Open Sans';
  border: 2px solid #e9e9e9;
  border-radius: .35rem;
  padding: .5rem;
}

#form-description {
  display: flex;
  align-items: flex-start;
}

#description-input {
  min-height: 5rem;
  padding: .5rem;
}

.form-submit-section {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.create-shop-breadcrumb {
  font-size: 18px;
  font-weight: 500;
  color: #222222;
  border: 1px solid #222222;
  padding: .5rem 1rem;
  border-radius: 1.25rem;
  cursor: pointer;
}

.create-shop-breadcrumb:hover {
  background-color: #e9e9e9;
  box-shadow: 0 0 8px 1px #DEDEDE;
}

.create-shop-breadcrumb:active {
  background-color: #DEDEDE;
}

.create-shop-submit {
  font-size: 18px;
  font-weight: 500;
  color: white;
  background-color: #F1641E;
  border: 1px solid #DEDEDE;
  padding: .5rem 1rem;
  border-radius: 1.25rem;
  cursor: pointer;
}

.create-shop-submit:hover {
  background-color: #D95C1F;
  box-shadow: 0 0 8px 1px #DEDEDE;
}

.create-shop-submit:active {
  background-color: #C6541A;
}

.img-input {
  box-sizing: border-box;
  color: #919191;
  font-family: 'Open Sans';
  cursor: pointer;
}

.form-breadcrumbs {
  display: flex;
  gap: 1rem;
}

#update-submit-section {
  margin-top: 1rem;
  max-width: 70rem;
}

.modal-body-1 {
  padding: 1rem;
  width: fit-content;
}

.modal-heading-1 {
  margin: 0 0 1rem 0;
  padding: 0;
  font-family: 'Shippori Mincho';
  font-size: 28px;
}

.modal-buttons-1 {
  display: flex;
  justify-content: space-between;
}
