.img_url {
  margin: 0;
  font-size: 11px;
  font-weight: bold;
}

#price-label {
  display: flex;
  justify-content: space-between;
  min-width: 13.25rem;
  max-width: 19.75rem;
  padding-right: .25rem;
}

#inventory-input {
  width: fit-content;
}

.dollar-sign {
  color: #919191;
}
