#shop-details-page {
  display: flex;
}

#shop-details-container {
  width: 100%;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  gap: .75rem;
  margin: 0 3rem;
}

#shop-details-banner {
  width: 100%;
  height: 5rem;
  object-fit: cover;
}

#shop-details-title {
  margin: .75rem 0;
  padding: 0;
  font-family: 'Shippori Mincho';
  font-weight: 500;
  overflow-wrap: anywhere;
  min-width: 35rem;
  width: 100%;
  max-width: 1500px;
}

.shop-details-text {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

#shop-details-desc {
  width: 50%;
}

#shop-products-container {
  display: flex;
  justify-content: flex-start;
}

#shop-products {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.shop-product-link {
  text-decoration: none;
  color: #222222;
  font-weight: 500;
  border-radius: .75rem;
}

.shop-product-link:hover {
  box-shadow: 0 0 8px 1px #919191;
}

.shop-product-img {
  width: 18rem;
  height: 15rem;
  object-fit: cover;
  border-radius: .75rem .75rem 0 0;
}

.shop-product-info {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0 .5rem;
}

.product-link-text {
  margin: 0 0 .25rem 0;
  padding: 0;
}

#shop-details-update {
  font-size: 18px;
  font-weight: 500;
  color: #222222;
  border: 1px solid #222222;
  padding: .5rem 1rem;
  border-radius: 1.25rem;
  cursor: pointer;
}

#shop-details-update:hover {
  background-color: #e9e9e9;
  box-shadow: 0 0 8px 1px #e9e9e9;
}

#shop-details-update:active {
  background-color: #DEDEDE;
}
