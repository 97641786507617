#shops-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 0 3rem 0 3rem;
}

#shops-list-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-family: 'Shippori Mincho';
}

#shops-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  justify-content: flex-start;
  /* margin: 0 3rem; */
}

.shop-link {
  text-decoration: none;
  border-radius: .5rem;
}

/* .shop-link:hover {
  box-shadow: 0 0 8px 1px #DEDEDE;
} */

.shop-img {
  width: 18rem;
  height: 15rem;
  object-fit: cover;
  border-radius: .5rem .5rem 0 0;
}

.shop-link-title {
  margin: .5rem;
  width: 18rem;
}
