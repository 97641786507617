.session-modal {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border: 2px solid black;
  border-radius: 1.5rem;
  gap: 1rem;
}

.session-heading {
  font-size: 18px;
  color: #222222;
  margin: 0;
}

.session-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.session-section {
  display: flex;
  flex-direction: column;
  gap: .15rem;
}

.session-field {
  color: #222222;
  font-size: 12px;
  font-weight: 600;
}

.session-error {
  font-size: 12px;
  font-weight: 600;
  color: red;
}

.session-input {
  border: none;
  height: 2.5rem;
  border-radius: .35rem;
  border: 2px solid #e9e9e9;
  min-width: 20rem;
}

.session-input:focus {
  outline: none;
  border: 2px solid #DEDEDE;
}

.session-submit {
  border: none;
  background-color: #222222;
  color: white;
  font-weight: 600;
  padding: 1rem;
  border-radius: 2.5rem;
}

.session-submit:hover {
  cursor: pointer;
  background-color: #3E3E3E;
  box-shadow: 0 0 8px 1px #919191;
}

.submit-disabled {
  border: none;
  background-color: #919191;
  color: white;
  font-weight: 600;
  padding: 1rem;
  border-radius: 2.5rem;
}

.session-heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.register {
  cursor: pointer;
  background: none;
  font-weight: 600;
  color: #222222;
  border: 2px solid #222222;
  height: fit-content;
  padding: .5rem .75rem;
  border-radius: 1.5rem;
}

.register:hover {
  background-color: #e9e9e9;
  box-shadow: 0 0 8px 1px #e9e9e9;
}

.register:active {
  background-color: #DEDEDE;
}

#unauthenticated-dropdown {
  display: flex;
  flex-direction: column;
  margin: .5rem;
  gap: .5rem;
}
