.manager-body {
  display: flex;
  justify-content: center;
}

.manager-page {
  margin: 0 3rem;
  width: 100%;
  max-width: 1500px;
}

.manager-page-heading {
  font-family: 'Shippori Mincho';
  font-weight: 500;
  color: #222222;
}

.manager-headings-container {
  display: flex;
  align-items: center;
}

.manager-heading {
  display: flex;
  align-items: center;
  font-family: 'Shippori Mincho';
  font-weight: 500;
  padding: .25rem .75rem .5rem .75rem;
  color: #222222;
}

.manager-toggle {
  display: flex;
  align-items: center;
  font-family: 'Shippori Mincho';
  font-weight: 500;
  color: #F1641E;
  padding: .25rem .75rem .5rem .75rem;
  border-radius: 1.25rem;
}

.manager-toggle:hover {
  cursor: pointer;
  background-color: #e9e9e9;
}

.manager-container {
  display: flex;
  flex-direction: column;
}
