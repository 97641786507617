#profile-section {
  display: flex;
  align-items: center;
}

#shop-manager-link {
  font-size: 1.25rem;
  color: #919191;
  padding: .5rem 1rem;
  border-radius: 1.25rem;
}

#shop-manager-link:hover {
  background-color: #e9e9e9;
}

#cart-link {
  font-size: 1.25rem;
  color: #919191;
  padding: .5rem 1rem;
  border-radius: 1.25rem;
}

#cart-link:hover {
  background-color: #e9e9e9;
}

.profile-dropdown {
  position: absolute;
  z-index: 1;
  right: 1rem;
  top: 3.75rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid #DEDEDE;
  border-radius: .75rem;
}

#profile-button {
  border: none;
  background: none;
  font-size: 1.5rem;
  color: #919191;
  padding: .5rem 1rem;
  border-radius: 1.25rem;
  margin-right: 1rem;
}

#profile-button:hover {
  cursor: pointer;
  background-color: #e9e9e9;
}

.hidden {
  display: none;
}

.drop-down-text {
  margin: .1rem 0;
  font-size: 14px;
  font-weight: 500;
  color: #222222;
  padding: .5rem .75rem 0;
}

#drop-down-username {
  margin: .1rem 0;
  font-size: 14px;
  font-weight: 600;
  color: #222222;
}

.drop-down-link {
  text-decoration: none;
}

#navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

#home {
  text-decoration: none;
  font-family: 'Shippori Mincho';
  font-weight: bolder;
  font-size: 36px;
  letter-spacing: 1px;
  color: #F1641E;
}

#sign-out-link {
  text-decoration: none;
}

#log-out-section {
  padding: .5rem .75rem;
  border-radius: 0 0 .75rem .75rem;
}

#log-out {
  border: none;
  background: none;
  padding: 0;
  color: #222222;
}

#log-out:hover {
  cursor: pointer;
}

#log-out-section:hover {
  background-color: #e9e9e9;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: fit-content;
  background-color: #919191;
  color: white;
  text-align: center;
  padding: .5rem;
  border-radius: 6px;
  font-size: 14px;
  position: absolute;
  z-index: 1;
  right: 1.25rem;
  top: 4.25rem;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

#personal-links-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.personal-link-wrap {
  all: unset;
}

#personal-links-name {
  font-weight: bold;
  color: #919191;
}

.personal-link {
  cursor: pointer;
  color: #919191;
}

.personal-link:hover {
  color: #CBCBCB;
}
