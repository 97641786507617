#product-details-body {
  width: 100%;
  display: flex;
  justify-content: center;
}

#product-details-page {
  max-width: 1500px;
  width: 100%;
  margin: 0;
  margin-top: 2rem;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.product-details-images {
  width: 40%;
  display: flex;
  flex-direction: column;
}

#product-details-image {
  max-width: 750px;
  max-height: 25rem;
  object-fit: cover;
  border-radius: .5rem;
}

.product-details-information {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 30%;
  gap: 1rem;
}

#product-details-price {
  margin: 0;
  padding: 0;
  color: #222222;
}

.product-details-heading {
  margin: 0;
  padding: 0;
  color: #222222;
  font-size: 18px;
  overflow-wrap: break-word;
}

.product-details-desc {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  color: #919191;
  letter-spacing: .3px;
}

#product-details-shop {
  margin: 0;
  padding: 0;
}

#product-details-update {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  color: #222222;
  border: 1px solid #222222;
  padding: .5rem 0;
  border-radius: 1.25rem;
  cursor: pointer;
}

#product-details-update:hover {
  background-color: #e9e9e9;
  box-shadow: 0 0 8px 1px #e9e9e9;
}

#product-details-update:active {
  background-color: #DEDEDE;
}

#add-to-cart {
  cursor: pointer;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  color: white;
  background-color: #F1641E;
  border: 1px solid #919191;
  padding: .5rem 0;
  border-radius: 1.25rem;
}

#add-to-cart:hover {
  background-color: #C25017;
}

#add-to-cart:active {
  background-color: #913C11;
}

#product-shop {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 1rem;
}

#product-shop-icon {
  height: 4rem;
  width: 4rem;
  object-fit: cover;
  border-radius: 2rem;
}

#product-shop-title {
  color: #222222;
}

#product-small-images {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: .5rem 0;
  max-width: 750px;
  gap: 1%;
}

.product-small-image {
  width: 19%;
  height: 4rem;
  object-fit: cover;
  border-radius: .25rem;
  cursor: pointer;
}

.small-image-selected {
  width: 19%;
  height: 4rem;
  object-fit: cover;
  border-radius: .25rem;
  cursor: pointer;
  border: 2px solid #f3c5ae;
}

.product-small-image:hover {
  border: 2px solid #F1641E;
}

#qty-add-inputs {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.add-cart-success {
  margin: 0;
  padding: 0;
  margin-left: .5rem;
  color: #F1641E;
  font-size: 10px;
  font-weight: 600;
}
