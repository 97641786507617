.cart-item-container {
  display: flex;
  flex-direction: column;
  gap: .75rem;
  width: fit-content;
}

.cart-item-wrapper {
  width: fit-content;
}

.cart-item {
  all: unset;
  display: flex;
  gap: .5rem;
  border-radius: .5rem;
  height: 9rem;
  box-sizing: content-box;
  width: fit-content;
}

.cart-item:hover {
  box-shadow: 0 0 8px 1px #e9e9e9;
}

.cart-thumbnail {
  object-fit: cover;
  width: 12rem;
  height: 9rem;
  border-radius: .5rem 0 0 .5rem;
}

.cart-item-details {
  width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.cart-item-details-price {
  margin: 0;
  padding: 0;
  color: #222222;
  font-size: 14px;
}

.cart-item-headings {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
  gap: .5rem;
}

.cart-item-details-heading {
  margin: 0;
  padding: 0;
  color: #222222;
  font-size: 18px;
}

.cart-item-store {
  margin: 0;
  padding: 0;
  color: #E9E9E9;
  font-size: 14px;
}

.cart-item-store:hover {
  text-decoration: underline;
  color: #F1641E;
}

.cart-item-store:active {
  text-decoration: underline;
  color: #C25017;
}

.cart-item-details-category {
  margin: 0;
  padding: 0;
  color: #919191;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  flex-wrap: wrap;
}

.cart-item-details-desc {
  margin: 0;
  padding: 0;
  color: #919191;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
}

.cart-product-link {
  all: unset;
  cursor: pointer;
}

.cart-qty-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: .5rem .75rem .75rem 0;
}

.cart-qty-price {
  width: 8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-qty {
  display: flex;
  align-items: center;
  gap: .25rem;
}

.cart-qty-label {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  color: #919191;
}

.cart-qty-input {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  color: #919191;
  width: 3rem;
  padding: 2px;
  border: 2px solid #E9E9E9;
  border-radius: .3rem;
}

.cart-qty-input:focus {
  outline: none;
}

.cart-error {
  color: #F1641E;
  font-size: 10px;
  font-weight: 600;
  width: 8rem;
}

#sub-cart-items {
  background-color: #919191;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40.25rem;
  height: 3rem;
  border-radius: .5rem;
  padding: 0 .5rem 0 1rem;
}

#subtotal-text {
  color: white;
  letter-spacing: .5px;
}

.clear-cart-modal {
  all: unset;
  background-color: none;
}

#clear-cart {
  cursor: pointer;
  color: white;
  width: 1rem;
  height: 1rem;
  padding: .5rem;
  border-radius: 100%;
}

#clear-cart:hover {
  background-color: #747474;
}

#clear-cart:active {
  background-color: #575757;
}

.placeholder-text {
  font-family: 'Shippori Mincho';
  font-weight: 500;
  color: #919191;
}

#clear-cart-item {
  cursor: pointer;
  color: #E9E9E9;
  text-align: right;
}

#clear-cart-item:hover {
  color: #BABABA;
}

#clear-cart-item:active {
  color: #8B8B8B;
}

.clear-qty-section {
  display: flex;
  flex-direction: column;
}
