#landing-products-page {
  margin: 0 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

#landing-products-container {
  display: flex;
  max-width: 1500px;
  gap: .5rem;
  margin: 2rem 0 1rem;
}

#large-link {
  width: 40%;
  position: relative;
}

#large-price {
  bottom: 7%;
}

#large-landing-product {
  width: 100%;
}

#large-product-img {
  width: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
  border-radius: .75rem;
}

#large-product-img:hover {
  box-shadow: 0 0 8px 1px #919191;
}


#small-landing-products {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: .25rem;
}

.landing-link {
  width: 33%;
  margin: 0;
  padding: 0;
}

.small-product-img {
  width: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
  border-radius: .75rem;
}

.small-product-img:hover {
  box-shadow: 0 0 8px 1px #919191;
}

.small-row {
  margin: 0;
  padding: 0;
  display: flex;
  gap: .5rem;
}

#landing-carousel-section {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1500px;
  margin-top: 3rem;
}

.landing-carousel-img {
  height: 15rem;
  object-fit: cover;
  border-radius: .75rem;
}

.thumb {
  height: 3.5rem;
}

.control-arrow {
  width: 15%;
  border-radius: .75rem;
}

.carousel-status {
  visibility: hidden;
}

.thumbs-wrapper {
  visibility: hidden;
}

.landing-link {
  position: relative;
}

.small-price {
  position: absolute;
  margin: 0;
  padding: 0;
  bottom: 10%;
  right: 5%;
  color: #919191;
  background-color: white;
  width: fit-content;
  padding: .1rem .5rem;
  font-size: 12px;
  font-weight: bolder;
  border-radius: .6rem;
}

.shop-carousel-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-products-link {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Shippori Mincho';
  color: white;
  background-color: #F1641E;
  border: 1px solid #919191;
  padding: .5rem 1rem;
  border-radius: 1.25rem;
}

.landing-products-link:hover {
  background-color: #D95C1F;
  box-shadow: 0 0 8px 1px #DEDEDE;
}

.landing-products-link:active {
  background-color: #C6541A;
}

#product-link {
  max-width: 1500px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.shop-carousel-info {
  display: flex;
  /* justify-content: center; */
  text-decoration: none;
  gap: .75rem;
  position: absolute;
  /* top: 35%; */
  /* left: 40%; */
  background-color: white;
  padding: 1rem;
  border-radius: 3rem
}

.shop-carousel-info:hover {
  box-shadow: 0 0 8px 1px #919191;
}


.landing-shop-title {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: 'Shippori Mincho';
  color: #919191;
}

/* #landing-products-page {
  margin: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

#landing-products-container {
  display: flex;
  gap: .75rem;
  width: 100%;
  max-width: 1500px;
}

#large-link {
  width: 40%;
}

#large-landing-product {}

#large-product-img {
  aspect-ratio: 4/3;
  width: 100%;
  object-fit: cover;
  border-radius: .75rem;
}

#large-price {
  position: relative;
  bottom: 14%;
  left: 81%;
  background-color: white;
  width: fit-content;
  padding: .1rem .25rem;
  font-size: 12px;
  font-weight: 600;
  border-radius: .5rem;
}

#small-landing-products {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
}

.small-row {
  display: flex;
  gap: .75rem;
}

.small-product-img {
  width: 100%;
  height: 50%;
  object-fit: cover;
  border-radius: .75rem;
}

.small-price {
  position: relative;
  bottom: 35%;
  left: 60%;
  background-color: white;
  width: fit-content;
  padding: .1rem .25rem;
  font-size: 12px;
  font-weight: 600;
  border-radius: .5rem;
}

.landing-link {
  border-radius: .75rem;
  width: 30%;
  text-decoration: none;
}

.landing-link:hover {
  box-shadow: 0 0 8px 1px #919191;
}

.landing-products-link {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Shippori Mincho';
  color: white;
  background-color: #F1641E;
  border: 1px solid #919191;
  padding: .5rem 1rem;
  border-radius: 1.25rem;
  margin-right: 5%;
}

.landing-products-link:hover {
  background-color: #D95C1F;
  box-shadow: 0 0 8px 1px #DEDEDE;
}

.landing-products-link:active {
  background-color: #C6541A;
}

#landing-links {
  max-width: 1500px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

#landing-shops-link {
  color: #222222;
  background-color: #e9e9e9;
}

#landing-shops-link:hover {
  background-color: #DEDEDE;
  box-shadow: 0 0 8px 1px #DEDEDE;
}

#landing-shops-link:active {
  background-color: #d2d2d2;
} */
