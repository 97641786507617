.manager-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.manager-create {
  width: 18rem;
  font-family: 'Shippori Mincho';
  font-size: 18px;
  font-weight: 500;
  color: white;
  background-color: #F1641E;
  border: 1px solid #919191;
  padding: .25rem 0 .5rem 0;
  border-radius: 1.25rem;
  cursor: pointer;
}

.manager-create:hover {
  background-color: #D95C1F;
  box-shadow: 0 0 8px 1px #919191;
}

.manager-create:active {
  background-color: #C6541A;
}

#shops-list {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  justify-content: flex-start;
}

.shop-list-item {
  border-radius: .5rem;
}

.shop-list-item:hover {
  box-shadow: 0 0 8px 1px #919191;
}

.shop-img {
  width: 20rem;
  height: 15rem;
  object-fit: cover;
  border-radius: .5rem .5rem 0 0;
}

.shops-list-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;
  padding: 0 .5rem .5rem .5rem;
}

.shops-list-text {
  text-decoration: none;
  color: #222222;
}

.shops-list-title {
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  padding: 0;
}

.shops-list-category {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.shops-list-update {
  text-decoration: none;
  color: #919191;
  padding: .5rem;
  border-radius: 2rem;
}

.shops-list-update:hover {
  background-color: #e9e9e9;
}
