/* TODO Add site wide styles */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans");
@import url("https://fonts.googleapis.com/css2?family=Shippori+Mincho");
@import url("https://fonts.googleapis.com/css2?family=Cormorant");

html,
body {
  margin: 0;
  font-family: 'Open Sans';
}
